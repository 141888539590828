import * as XXV from "../index"; // Importing the module
const { fetchDataFromRoute } = XXV; // Destructuring the fetchDataFromRoute function

document.addEventListener("DOMContentLoaded", async (e) => {
    e.preventDefault();

    const navbar = document.getElementById("navbar");
    const sidebar = document.getElementById("sidebar");
    const btnSidebarToggler = document.getElementById("btnSidebarToggler");
    const navClosed = document.getElementById("navClosed");
    const navOpen = document.getElementById("navOpen");

    btnSidebarToggler.addEventListener("click", (e) => {
        e.preventDefault();
        sidebar.classList.toggle("show");
        navClosed.classList.toggle("hidden");
        navOpen.classList.toggle("hidden");
    }); 

    sidebar.style.top = parseInt(navbar.clientHeight) - 1 + "px";

    try {
        // Fetch affiliate equity
        const affEd = await getAffiliateEquity();
    
        // Save affEd to localStorage
        localStorage.setItem("affiliateEquity", affEd);
    
        // Fetch MRP and user data
        const { MRP } = await fetchDataFromRoute("mrp", "get", null, null);
        const { user } = await fetchDataFromRoute("users", "get", null, null);
    
        // Calculate MRP balance
        const MRPBalance =
            MRP.length < 1
                ? 0
                : MRP.reduce(
                      (accumulator, currentValue) =>
                          accumulator + currentValue.currentAmount,
                      0
                  );

        console.log("bal:",user.balance)
        // Calculate final balance (subtracting affEd)
        const finalBalance =  Number(affEd) - Number(user.balance) ;
        localStorage.setItem("finale", finalBalance);
        console.log(finalBalance);
    
        // Update the DOM with user details and balance
        document.querySelector("#my-user-username").textContent = `Hi ${
            String(user.fullName).split(" ")[0]
        }`;
    
        document.querySelector("#my-user-aff-bal").textContent = `${String(
            user.accountCurrency
        )} ${Number(finalBalance + Number(MRPBalance)).toFixed(2)}`;
    
        console.log("Affiliate Equity:", affEd);
        console.log("MRP Balance:", MRPBalance);
        console.log("User Details:", user);

        // Handle GiftBox status
        const { accountGiftBoxStatus } = user;

        if (accountGiftBoxStatus == "Inactive") {
            document.querySelector(
                ".gift-box-request"
            ).textContent = `Activate GiftBox`;
            document
                .querySelector(".gift-box-request")
                .addEventListener("click", async (e) => {
                    e.preventDefault();

                    const res = await fetchDataFromRoute(
                        "users",
                        "patch",
                        {
                            accountGiftBoxStatus: "Pending",
                        },
                        null
                    );

                    console.log(res);
                    window.location.reload();
                });
        }

        if (accountGiftBoxStatus == "Pending") {
            document.querySelector(
                ".gift-box-request"
            ).textContent = `Pending | Cancel`;
            document
                .querySelector(".gift-box-request")
                .addEventListener("click", async (e) => {
                    e.preventDefault();

                    const res = await fetchDataFromRoute(
                        "users",
                        "patch",
                        {
                            accountGiftBoxStatus: "Inactive",
                        },
                        null
                    );

                    console.log(res);
                    window.location.reload();
                });
        }

        if (accountGiftBoxStatus == "Active") {
            document.querySelector(
                ".gift-box-request"
            ).textContent = `GiftBox Activated`;
            document
                .querySelector(".gift-box-request")
                .classList.add("!bg-[#28be39]");
            document
                .querySelector(".gift-box-request")
                .setAttribute("disabled", true);
        }
    } catch (error) {
        console.log(error);
    }

    // Sign out functionality
    document.querySelectorAll(".sign-out-acc").forEach((s) =>
        s.addEventListener("click", async (e) => {
            e.preventDefault();

            localStorage.removeItem("uvtkn");

            window.location.href = "login.html";
        })
    );
});

// Function to calculate and send affiliate equity
async function getAffiliateEquity() {
    try {
        const { plans } = await fetchDataFromRoute("plans", "get");
        loaderContainer.classList.remove("hidden");

        const generatedEquity = [];

        for (const planName in plans) {
            if (plans.hasOwnProperty(planName)) {
                const planData = plans[planName];

                if (Array.isArray(planData) && planData.length > 0) {
                    console.log(`Making requests for plan: ${planName}`);

                    const promises = planData.map(async (item) => {
                        console.log(`  Fetching data for ${planName}: ${item}`);
                        const res = await fetchDataFromRoute(
                            `plans/${item}`,
                            "get",
                            null,
                            { planType: planName }
                        );
                        return Math.floor(res.planDetails.currentAmount);
                    });

                    const results = await Promise.all(promises);

                    generatedEquity.push(...results);
                }
            }
        }

        loaderContainer.classList.add("hidden");

        // Calculate total affiliate equity
        const totalEquity = generatedEquity.length > 0
            ? generatedEquity.reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
              )
            : 0;

        // Fetch user data to get userId
        const { user } = await fetchDataFromRoute("users", "get", null, null);

        if (!user || !user._id) {
            console.error("User ID not found. Unable to update affiliate balance.");
            return totalEquity;
        }

        // Send the total affiliate equity and userId to the backend server
        try {
            const response = await fetchDataFromRoute(
                "balance/affiliate", // Backend route to update affiliate balance
                "patch",
                { userId: user._id, equity: totalEquity }, // Added userId
                null
            );

            console.log("Affiliate equity sent to backend:", response);
        } catch (error) {
            console.error("Failed to send affiliate equity to backend:", error);
        }

        return totalEquity;
    } catch (error) {
        console.log(error);
    }
}
